<template>
  <BaseModal responsible-ready-modal :title="$t('responsibleGaming.modal.title')" :dim-close="false" :show-close="false">
    <template #content>
      <div class="content">
        <GpDescription :message="description" />
        <p class="sub-title">{{ $t('responsibleGaming.modal.subTitle.responsibleGamingLimit') }} :</p>
        <div class="body">
          <div class="list-item" v-for="item in items">
            <FontIcon class="verify-icon" name="check-thin" />
            <p>{{ $t(`responsibleGaming.title.${item.label}`) }}</p>
          </div>
        </div>
        <div class="footer">
          <GpButton type="line-green" @click="reject">{{ $t('reject') }}</GpButton>
          <GpButton type="submit-green" @click="confirm">{{ $t('approve') }}</GpButton>
        </div>
      </div>
    </template>
  </BaseModal>
</template>

<script>

import BaseModal from '@/views/components/gg-pass/modal/BaseModal.vue';
import specific from '@shared/types/Specific';
import GpButton from '@/views/components/gg-pass/GpButton.vue';
import RadioList from '@/views/components/gg-pass/RadioList.vue';
import GpDescription from '@/views/components/gg-pass/GpDescription.vue';
import FontIcon from '@shared/components/common/FontIcon.vue';
import Specific from '@shared/types/Specific';
import { siteIds } from '@/constants/base/siteMap.mjs';
import { state } from '@shared/utils/storeUtils.mjs';

export default {
  name: 'ResponsibleReadyModal',
  components: { FontIcon, GpDescription, RadioList, GpButton, BaseModal },
  props: {
    options: Specific,
  },
  data() {
    return {
      items: [],
      payloads: [],
    };
  },
  computed: {
    site: state('env', 'site'),
    responsibleModel() {
      return {
        HasReadyAutomaticReserveWin: {
          label: { default: 'automaticReserveWin' },
          apply: 'ApplyReadyAutomaticReserveWin',
        },
        HasReadyBalanceLimit: {
          label: { default: 'balanceLimit', [siteIds.GGPCZ]: 'maximumBalance', [siteIds.GGPNL]: 'maximumBalance' },
          apply: 'ApplyReadyBalanceLimit',
        },
        HasReadyDepositLimit: {
          label: { default: 'depositLimit', [siteIds.GGPDE]: 'localDepositLimit' },
          apply: 'ApplyReadyDepositLimit',
        },
        HasReadyGameLimit: {
          label: { default: 'gameLimit' },
          apply: 'ApplyReadyGameLimit',
        },
        HasReadyLoginTimeLimit: {
          label: { default: 'playTimeLimit', [siteIds.GGPNL]: 'loginTimeLimit' },
          apply: 'ApplyReadyLoginTimeLimit',
        },
        HasReadyLossLimit: {
          label: { default: 'lossLimit' },
          apply: 'ApplyReadyLossLimit',
        },
        HasReadyMonthlyLoginFrequency: {
          label: { default: 'monthlyLogin' },
          apply: 'ApplyReadyMonthlyLoginFrequency',
        },
        HasReadyReLoginTimeLimit: {
          label: { default: 'reLoginLimit' },
          apply: 'ApplyReadyReLoginTimeLimit',
        },
        HasReadySingleBetLimit: {
          label: { default: 'singleBetLimit' },
          apply: 'ApplyReadySingleBetLimit',
        },
        HasReadyTotalBetLimit: {
          label: { default: 'totalBetLimit' },
          apply: 'ApplyReadyTotalBetLimit',
        },
      };
    },
    description() {
      return `${this.$t('responsibleGaming.modal.subTitle.readyModalApprove')} <br /> ${this.$t('responsibleGaming.modal.subTitle.readyModalReject')}`;
    }
  },
  methods: {
    initialize() {
      this.items = Object.keys(this.options).filter(x => this.options[x]).map(v => {
        const resModel = this.responsibleModel[v];
        const label = resModel.label[this.site] || resModel.label.default;
        return {
          label: label,
          value: resModel.value,
        };
      });
      this.payloads = Object.keys(this.options).reduce((acc, cur) => {
        acc[this.responsibleModel[cur].apply] = this.options[cur];
        return acc;
      }, {});
    },

    close() {
      this.$modalCloseAll();
    },

    async reject() {
      const payload = {
        ...this.payloads,
        IsApprove: false,
      };
      const result = await this.$services.account.reaffirmResponsibleGaminConfig(payload);

      if (result.error) {
        return;
      }

      window.location.reload();
    },

    async confirm() {
      const payload = {
        ...this.payloads,
        IsApprove: true,
      };

      const result = await this.$services.account.reaffirmResponsibleGaminConfig(payload);

      if (result.error) {
        return;
      }

      window.location.reload();
    },
  },
  mounted() {
    this.initialize();
  }
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[responsible-ready-modal] {
  > .panel { .rel(); overflow: visible; background-color: #f7f7f8;
    .scroll-area {.h(calc(100% - 59px)); .flex(); .flex-dc(); overflow-y: visible;
      > .body { flex: 1; }
    }
  }
  .content {
    [gp-description] {.pt(0); .pb(20); text-align: center;
      > p {line-height: 20px;}
    }
    .sub-title {.c(black); .fs(14); font-weight: 500;}
    .body {.mt(5); .flex();.flex-dc(); .max-h(210); overflow-y: auto; .bgc(#fff); .br(16); .pv(16);
      .list-item { .flex(); .flex-dr(); .ph(15); .pv(2); .gap(8); .items-center();
        .verify-icon {.c(#249135); .fs(16);}
        > p {.c(#787878);}
      }

    }
  }
  .footer {.flex(); .gap(10); .mt(20);}


}
</style>