<template>
  <footer v-if="showFooterBrand && copyRight" gp-footer>
    <p v-html="copyRight" />
  </footer>
</template>

<script>

import { state } from '@shared/utils/storeUtils.mjs';
import { brandIds, siteIds } from '@/constants/base/siteMap.mjs';
import moment from 'moment';
export default {
  name: 'GpFooter',
  lexicon: 'footer',
  computed: {
    site: state('env', 'site'),
    brand: state('env', 'brand'),
    isMgaSite() {
      return [siteIds.GGPEU, siteIds.GGPHU, siteIds.GGPNL, siteIds.GGPDE, siteIds.GGPFI].indexOf(this.site) >= 0;
    },
    isKWSite() {
      return [siteIds.GGPPL, siteIds.GGPBR, siteIds.GGPCW].indexOf(this.site) >= 0;
    },
    isDefaultFooter() {
      return [siteIds.GGPEU, siteIds.GGPHU, siteIds.GGPNL, siteIds.GGPDE, siteIds.GGPPL, siteIds.GGPBR, siteIds.GGPCW, siteIds.GGPFI, siteIds.GGPCZ, siteIds.GGPPH].indexOf(this.site) < 0;
    },
    isGGPCZ() {
      return this.site === siteIds.GGPCZ;
    },
    isGGPPH() {
      return this.site === siteIds.GGPPH;
    },
    isWSOP() {
      return this.site === siteIds.WSOPON;
    },
    isGGPUK() {
      return this.site === siteIds.GGPUK;
    },
    utcNow() {
      return moment.utc().format('YYYY');
    },
    showFooterBrand() {
      return this.brand === brandIds.GGPCOM || this.brand === brandIds.GGPOKERUK || this.brand === brandIds.WSOP;
    },
    copyRight() {
      if (!this.showFooterBrand) return null;
      if (this.isMgaSite) return this.$t('_.maltaLimit', { utcNow: this.utcNow });
      else if (this.isKWSite) return this.$t('_.kahnawakeSite', { utcNow: this.utcNow });
      else if (this.isGGPCZ) return this.$t('_.ggpcz', { utcNow: this.utcNow });
      else if (this.isGGPPH) return this.$t('_.ggpph', { utcNow: this.utcNow });
      else if (this.isWSOP) return this.$t('_.wsop', { utcNow: this.utcNow });
      else if (this.isGGPUK) return this.$t('_.ggpuk', { utcNow: this.utcNow });
      else if (this.isDefaultFooter) return this.$t('_.ggpcom', { utcNow: this.utcNow });
      else return null;
    },
  },
};
</script>

<style lang="less">
@import '~@/less/proj.less';
[gp-footer] { .bgc(#fafafa);
  > p {.c(#8A8E90); .fs(12);}
}
</style>